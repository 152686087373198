import React from "react";

const TrustItem = ({ src, description }) => {
  return (
    <div
      className="col-span-1 flex justify-center relative"
      key={description}
    >
      <img className="max-h-12" src={src} alt={description} />
    </div>
  );
};

export default TrustItem;
