import React, { useState, useEffect, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

import { initialFormFields } from "../utils/utils";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";

const ContactSection = ({
  title,
  description,
  email,
  phone,
  firstNameLabel,
  emailLabel,
  phoneLabel,
  interestCheckboxLabel,
  messageLabel,
  checkboxLabel1,
  checkboxLabel2,
  checkboxLabel3,
  checkboxLabel4,
  checkboxLabel5,
  checkboxLabel6,
  checkboxLabel7,
  submitButton,
  submitButtonSuccess,
  subjectMail,
}) => {
  const recaptchaRef = createRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [formIsSent, setFormIsSent] = useState(false);

  const [showMore, setShowMore] = useState(false);

  const [siteKey, setSiteKey] = useState("");
  const [, setSiteUrl] = useState("");

  const [isVerified, setIsVerified] = useState(false);

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
    setSiteUrl(data.site_url);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setName = (event) => {
    setFormFields({ ...formFields, name: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setInterest = (event) => {
    setFormFields({ ...formFields, interest: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };

  const onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        email: formFields.email,
        name: formFields.name,
        phone: formFields.phone,
        interest: formFields.interest,
        message: formFields.message,
        subject: subjectMail,
        recaptcha: recaptchaValue,
      }),
    })
      .catch((err) => {
        new Error(err);
        setFormIsSent(false);
      })
      .then((res) => {
        setFormIsSent(true);
        setFormFields(initialFormFields);
      });
  };

  return (
    <div className="relative bg-white" id="contacts">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-stone-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-stone-50 py-12 px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-3xl font-extrabold tracking-tight text-stone-900 sm:text-4xl">
              {title}
            </h2>
            <p className="mt-3 text-lg leading-6 text-stone-500">
              {description}
            </p>
            <dl className="mt-8 text-base text-stone-500">
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-stone-400"
                    aria-hidden="true"
                  />
                  <a className="ml-3" href={`tel:${phone}`}>
                    {phone}
                  </a>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-stone-400"
                    aria-hidden="true"
                  />
                  <a className="ml-3" href={`mailto:${email}`}>
                    {email}
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-12 px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  {firstNameLabel}
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-stone-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-pink-600 border border-stone-300 rounded-md"
                  placeholder="Full name"
                  onChange={setName}
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  {emailLabel}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-stone-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-pink-600 border border-stone-300 rounded-md"
                  placeholder="Email"
                  onChange={setEmail}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  {phoneLabel}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-stone-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-pink-600 border border-stone-300 rounded-md"
                  placeholder="Phone"
                  onChange={setPhone}
                  required
                />
              </div>
              {interestCheckboxLabel && checkboxLabel1 && (
                <fieldset
                  className={`overflow-hidden ${showMore ? "" : "h-0"}`}
                >
                  <legend className="block w-full text-sm font-medium text-stone-500">
                    <div className="">
                      {showMore ? (
                        <button
                          type="button"
                          onClick={() => setShowMore(!showMore)}
                          className="cursor-pointer inline-flex border-none items-center text-sm font-medium focus:outline-none"
                        >
                          {interestCheckboxLabel}
                          <ChevronUpIcon
                            className="flex-shrink-0 w-6 h-6 text-pink-800 pl-2"
                            aria-hidden="true"
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setShowMore(!showMore)}
                          className="relative cursor-pointer inline-flex w-full items-center text-sm font-medium focus:outline-none py-3 px-4 shadow-sm border-stone-300 border-2 rounded-md"
                        >
                          {interestCheckboxLabel}
                          <ChevronDownIcon
                            className="absolute right-4 flex-shrink-0 w-6 h-6 text-pink-800 pl-2"
                            aria-hidden="true"
                          />
                        </button>
                      )}
                    </div>
                  </legend>
                  <div className="mt-4 grid grid-cols-1 gap-y-4">
                    <div className="flex items-center">
                      <input
                        id={`interest-${checkboxLabel1
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                        name="interest"
                        defaultValue={checkboxLabel1
                          .toLowerCase()
                          .split(" ")
                          .join("-")}
                        type="radio"
                        onBlur={setInterest}
                        className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                      />
                      <label
                        htmlFor={`interest-${checkboxLabel1
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                        className="ml-3"
                      >
                        <span className="block text-sm text-warm-gray-900">
                          {checkboxLabel1}
                        </span>
                      </label>
                    </div>
                    {checkboxLabel2 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel2
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel2
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel2
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel2}
                          </span>
                        </label>
                      </div>
                    )}
                    {checkboxLabel3 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel3
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel3
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel3
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel3}
                          </span>
                        </label>
                      </div>
                    )}
                    {checkboxLabel4 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel4
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel4
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel4
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel4}
                          </span>
                        </label>
                      </div>
                    )}
                    {checkboxLabel5 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel5
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel5
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel5
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel5}
                          </span>
                        </label>
                      </div>
                    )}
                    {checkboxLabel6 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel6
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel6
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel6
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel6}
                          </span>
                        </label>
                      </div>
                    )}
                    {checkboxLabel7 && (
                      <div className="flex items-center">
                        <input
                          id={`interest-${checkboxLabel7
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          name="interest"
                          defaultValue={checkboxLabel7
                            .toLowerCase()
                            .split(" ")
                            .join("-")}
                          type="radio"
                          onBlur={setInterest}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                        />
                        <label
                          htmlFor={`interest-${checkboxLabel7
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="ml-3"
                        >
                          <span className="block text-sm text-warm-gray-900">
                            {checkboxLabel7}
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                </fieldset>
              )}
              <div>
                <label htmlFor="message" className="sr-only">
                  {messageLabel}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-stone-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-pink-600 border border-stone-300 rounded-md"
                  placeholder="Message"
                  defaultValue={""}
                  onChange={setMessage}
                />
              </div>
              <div>
                {siteKey &&
                  formFields.name &&
                  formFields.email &&
                  formFields.phone && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      badge={"inline"}
                      sitekey={siteKey}
                      onErrored={() => setIsVerified(false)}
                      onExpired={() => setIsVerified(false)}
                      onChange={() => setIsVerified(true)}
                    />
                  )}

                {!formIsSent ? (
                  <button
                    type="button"
                    onClick={onSubmit}
                    disabled={
                      !formFields.name ||
                      !formFields.email ||
                      !formFields.phone ||
                      !isVerified
                    }
                    className="inline-flex justify-center items-center py-3 px-4 rounded-md shadow bg-pink-700 text-white font-medium hover:bg-pink-600 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-pink-600 focus:ring-offset-stone-900 disabled:bg-stone-400"
                  >
                    {submitButton}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    {submitButtonSuccess}
                    <CheckIcon className="h-6 w-6 ml-2 " />
                  </button>
                )}

                {/* <button
                type="submit"
                className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                {submitButton}
              </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
