import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const Blockquote = ({ logoImageName, text, avatarImageName, name, role }) => (
  <blockquote className="relative bg-white rounded-lg shadow-lg">
    <div className="rounded-t-lg px-4 py-4 sm:px-8">
      <GatsbyImage
        image={useImageForData(logoImageName)}
        className="max-w-[12rem]"
        imgClassName="max-w-[12rem]"
        alt=""
      />
      <div className="relative text-lg text-gray-700 font-medium mt-4">
        <svg
          className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
          fill="currentColor"
          viewBox="0 0 32 32"
          aria-hidden="true"
        >
          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
        </svg>
        <p className="relative">
          {text}
        </p>
      </div>
    </div>
    <cite className="relative flex items-center sm:items-start bg-pink-800 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-8">
      <div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
        <GatsbyImage
          image={useImageForData(avatarImageName)}
          className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-pink-300"
          imgClassName="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-pink-300"
          alt=""
        />
      </div>
      <span className="relative ml-4 text-pink-300 font-semibold leading-6 sm:ml-24 sm:pl-1">
        <p className="text-white font-semibold sm:inline">{name}</p>{" "}
        <p className="sm:inline">{role}</p>
      </span>
    </cite>
  </blockquote>
);

export default Blockquote;
