import React from "react";
import {
  ServerIcon,
  GlobeAltIcon,
  DeviceMobileIcon,
  CodeIcon,
  DesktopComputerIcon,
  InboxIcon,
  CursorClickIcon,
  WifiIcon,
} from "@heroicons/react/solid";

import TrustSection from "../components/sections/TrustSection";
import TrustItem from "../components/elements/TrustItem";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import PortfolioSection from "../components/sections/PortfolioSection";
import ContactSection from "../components/sections/ContactSection";
import Footer from "../components/sections/Footer";
import ActionCallSection from "../components/sections/ActionCallSection";
import PageTitle from "../components/elements/PageTitle";
import GodaIcon from "../images/godagrannen.png";
import EmotianIcon from "../images/emotian.png";
import ElfsterIcon from "../images/elfster.png";
import Wally from "../images/wally.png";
import PinpointerIcon from "../images/pinpointer.png";
import DvigexIcon from "../images/dvigex.png";
import TestimonialsSection from "../components/sections/TestimonialsSection";
import TestimonialItem from "../components/elements/TestimonialItem";
import ProductQualitySection from "../components/sections/ProductQualitySection";
import Blockquote from "../components/elements/Blockquote";
import SchemaSection from "../components/sections/SchemaSection";
import SchemaItem from "../components/elements/SchemaItem";

const indexPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Code Expert"} description={``} />
    <ActionCallSection
      mainTitle={`Software Solutions`}
      highlightedText={`for Every Business`}
      description={`We develop applications. We realise your ideas. Go together hand-in-hand.`}
      /* coloredButtonText="Get started"
      coloredButtonPath="/"
      uncoloredButtonText="Live demo"
      uncoloredButtonPath="/"*/
    />
    <TrustSection title="TRUSTED BY" className="items-center">
      <TrustItem src={GodaIcon} description={"GodaGrannen"} />
      <TrustItem src={EmotianIcon} description={"Emotian"} />
      <TrustItem src={ElfsterIcon} description={"Elfster"} />
      <TrustItem src={Wally} description={"Wally"} />
      <TrustItem src={PinpointerIcon} description={"Pinpointer"} />
      <TrustItem src={DvigexIcon} description={"DvigexIcon"} />
    </TrustSection>
    {/* <CompetencesSection
      title={"Our competences"}
      description={"Some description"}
    >
      <CompetenceItem
        title="some title"
        description={`specialize in custom software development, mobile application development`}
        Icon={CogIcon}
      />
      <CompetenceItem title="some title" description={`dfs`} Icon={CogIcon} />
      <CompetenceItem title="some title" description={`dfs`} Icon={CogIcon} />
      <CompetenceItem title="some title" description={`dfs`} Icon={CogIcon} />
    </CompetencesSection> */}
    <ServicesSection title={`Our competences`} description={``}>
      <ServiceItem
        name={`Web applications`}
        description={`Apps that launched via any browser/on any device  and need internet to be used. High speed of development, but low performance`}
        Icon={GlobeAltIcon}
      />
      <ServiceItem
        name={`Mobile Applications`}
        description={`Cross-platform mobile apps for often usage, with notifications and high performance; possible to go offline, hardware friendly`}
        Icon={DeviceMobileIcon}
      />
      <ServiceItem
        name={`CRM & ERP Systems`}
        description={`Tools to manage company’s relationships and interactions with customers, manage day-to-day activities of a business`}
        Icon={ServerIcon}
      />
      <ServiceItem
        name={`Landing Pages`}
        description={`Web pages with content that provide a digital presence for a business, important for branding purposes`}
        Icon={CodeIcon}
      />
      <ServiceItem
        name={`FinTech Applications`}
        description={`Applications designed to automate or digitize financial operations: web-payments, mobile payments, P2P/B2C, EMV, PSP, PCI, NFC`}
        Icon={DesktopComputerIcon}
      />
      <ServiceItem
        name={`Soft- & Hardware Integration`}
        description={`Software to enable processing data from special hardware and  to interact with them using USB, Bluetooth, NFC`}
        Icon={InboxIcon}
      />
      <ServiceItem
        name={`Enterprise automation systems`}
        description={`Software to automate manual or repetitive business processes using BPML, SOA, EAI, ETL, OAuth`}
        Icon={CursorClickIcon}
      />
      <ServiceItem
        name={`IoT (Internet of Things)`}
        description={`Network of devices connected to the Internet and able to  interact with each other and humans in real time`}
        Icon={WifiIcon}
      />
    </ServicesSection>
    <SchemaSection title="How do we make expectation and reality be equal?">
      <SchemaItem
        text="DATA DOMAIN analysis using entity relationship diagrams"
        ind={1}
      />
      <SchemaItem
        text="Working with domain experts using UBIQUITOUS language"
        ind={2}
      />
      <SchemaItem
        text="BUSINESS PROCESSES analysis with flowcharts, sequence diagrams"
        ind={3}
      />
      <SchemaItem
        text="SYSTEM analysis using component diagrams and design patterns"
        ind={4}
      />
      <SchemaItem
        text="Creating PROTOTYPE on pre-sale stage and  approve UX and UI with customer"
        ind={5}
      />
      <SchemaItem
        text="A/B TESTING to compare wo variants of a specific feature"
        ind={6}
        isLastItem={true}
      />
    </SchemaSection>
    <ProductQualitySection
      title="How do we ensure product quality?"
      description={``}
      articleTitle="HOW DO WE ENSURE PRODUCT QUALITY?"
      firstBtnTitle="Contact us"
      firstBtnUrl="https://codeexpert.se/#contacts"
      secondBtnTitle=""
      secondBtnUrl="#"
    >
      <Blockquote
        logoImageName="codeexpertGREY.png"
        text={`We work according to our own unique software development process: the whole process is divided into two parallel streams that run simultaneously: DEVELOPMENT in the current sprint and ANALYSIS for the next sprint. This approach is based on Kanban methodology, but with some differences, and provides psychological comfort to the developers.`}
        avatarImageName="david.jpg"
        name="David Hamren"
        role="CEO at Code Expert"
      />
    </ProductQualitySection>
    <PortfolioSection
      title="Our Portfolio"
      showMoreText="Show more projects"
      showMorePath="/portfolio/"
      issuesId="9064,17880,8835"
    />
    <TestimonialsSection title="Personalities" description="">
      <TestimonialItem
        name={"Douglas Oest"}
        role={"Strategy and Business development manager"}
        description={``}
        imageName={"douglas.jpg"}
      />
      <TestimonialItem
        name={"Kirill Kovalchuk"}
        role={"COO - Chief Operating Officer"}
        description={``}
        imageName={"kirill.jpg"}
      />
      <TestimonialItem
        name={"David Hamren"}
        role={"CEO – Chief Executive Officer"}
        description={``}
        imageName={"david.jpg"}
      />
    </TestimonialsSection>
    <ContactSection
      title={"Contact with us"}
      description={`Please, leave your contacts or write/call us directly`}
      email={"ask@codeexpert.se"}
      phone={"+46705148055"}
      firstNameLabel={"Your name"}
      emailLabel={"Email"}
      phoneLabel={"Phone number"}
      // interestCheckboxLabel={"Select your interest"}
      messageLabel={"Message"}
      // checkboxLabel1={"Development"}
      // checkboxLabel2={"Maintenance"}
      // checkboxLabel3={"Consulting"}
      // checkboxLabel4={"QA and testing"}
      // checkboxLabel5={"DevOps"}
      submitButton={"Submit"}
      submitButtonSuccess={"Sent"}
      subjectMail={"Request from Contacts"}
    />
    <Footer />
  </div>
);

export default indexPage;
