import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const TestimonialItem = ({ name, role, description, imageName }) => (
  <li
    key={name}
    className="py-8 px-4 bg-pink-900/40 text-center rounded-lg xl:px-8 xl:text-left"
  >
    <div className="space-y-4 xl:space-y-8">
      <GatsbyImage
        image={useImageForData(imageName)}
        className="!block mx-auto h-40 w-40 rounded-full xl:w-44 xl:h-44"
        imgClassName="!block mx-auto h-40 w-40 rounded-full xl:w-44 xl:h-44"
        alt=""
      />
      <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
        <div className="text-lg leading-6 space-y-1">
          <h3 className="text-white font-medium text-2xl m-0">{name}</h3>
          <p className="text-pink-400/70 font-medium text-xl">{role}</p>
          <p className="text-gray-300 font-medium text-xl">{description}</p>
        </div>
      </div>
    </div>
  </li>
);

export default TestimonialItem;
