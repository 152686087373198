import React, { useRef, useEffect, useState } from "react";

const SchemaSection = ({ title, children }) => {
  const ref = useRef(null);
  const initialWindowWidth = ref.current ? ref.current.offsetWidth : 0;
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(initialWindowWidth);
  }, [initialWindowWidth]);

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        windowWidth: windowWidth,
      });
    });
  };

  return (
    <div ref={ref} className="border-stone-800 bg-stone-800 border-l-0 border-r-0">
      <h2 className="py-4 px-4 sm:py-6 sm:px-6 lg:px-8 m-0 text-center text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
        {title}
      </h2>
      <ol className="border schema grid sm:grid-cols-3 lg:sm:grid-cols-6 border-stone-800 bg-stone-800 border-l-0 border-r-0 divide-y divide-stone-800 md:divide-y-0">
        {/* Schema items */}
        {renderChildren()}
      </ol>
    </div>
  );
};

export default SchemaSection;
