import * as React from "react";

const ServicesSection = ({ title, description, children }) => (
  <div className="relative bg-white py-12">
    <div className="mx-auto max-w-7xl px-4 text-center sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-stone-900 tracking-tight sm:text-4xl">
        {title}
      </h2>
      <p className="mt-5 max-w-prose mx-auto text-xl text-stone-500 text-center">
        {description}
      </p>
      <div className="mt-8 sm:mt-12">
        <dl className="grid grid-cols-2 gap-2 sm:grid-cols-4 lg:gap-4">
          {children}
        </dl>
      </div>
    </div>
  </div>
);
export default ServicesSection;
