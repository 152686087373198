import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "./Header";

const backgroundImagePath =
  "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80";

const ActionCallSection = ({
  mainTitle,
  highlightedText,
  description,
  coloredButtonText,
  coloredButtonPath,
  uncoloredButtonText,
  uncoloredButtonPath,
}) => (
  <div className="relative overflow-hidden bg-stone-800">
    <div className="max-w-7xl mx-auto">
      <div className="bg-stone-800 relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <Header />
        <main className="mt-4 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl">
              <span className="block">{mainTitle}</span>{" "}
              <span className="block text-pink-600">{highlightedText}</span>
            </h1>
            <div className="mt-3 sm:mt-5 md:mt-5">
              {description &&
                description.split(". ").map((el, i) => (
                  <p
                    key={i}
                    className={
                      "text-base text-white/80 sm:text-lg sm:max-w-[34rem] sm:mx-auto md:text-xl lg:mx-0 " +
                      (el.includes(".") ? "block" : "inline")
                    }
                  >
                    {el + (el.includes(".") ? "" : ". ")}
                  </p>
                ))}
            </div>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              {coloredButtonText && (
                <div className="rounded-md shadow">
                  <Link
                    to={coloredButtonPath}
                    className="w-full flex items-center justify-center px-8 py-3 text-base font-medium text-white hover:bg-pink-800 bg-pink-700 inline-flex items-center border border-transparent rounded-md md:py-4 md:text-lg md:px-10"
                  >
                    {coloredButtonText}
                  </Link>
                </div>
              )}
              {uncoloredButtonText && (
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to={uncoloredButtonPath}
                    className="w-full flex items-center justify-center px-8 py-3 text-base font-medium text-pink-800 bg-pink-100 hover:bg-pink-200 inline-flex items-center border border-transparent rounded-md md:py-4 md:text-lg md:px-10"
                  >
                    {uncoloredButtonText}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <StaticImage
        src={backgroundImagePath}
        alt=""
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        imgClassName="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        placeholder="none"
      />
      <div className="absolute inset-0 bg-stone-800/[0.6]" />
    </div>
  </div>
);
export default ActionCallSection;
