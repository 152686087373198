import React from "react";

const SchemaItem = ({ text, ind, isLastItem, windowWidth }) => (
  <li key={text} className="relative md:flex-1 md:flex">
    <div
      className="px-6 py-4 flex items-center text-sm font-medium"
      aria-current="step"
    >
      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-pink-500 rounded-full">
        <span className="text-white font-bold">{ind}</span>
      </span>
      <span className="ml-4 text-sm font-medium text-white">{text}</span>
    </div>

    {(ind % 3 !== 0 && windowWidth < 1024) ||
    (windowWidth >= 1024 && !isLastItem) ? (
      <>
        {/* Arrow separator for lg screens and up */}
        <div
          className="hidden md:block absolute top-0 right-0 h-full w-5"
          aria-hidden="true"
        >
          <svg
            className="h-full w-full text-white"
            viewBox="0 0 22 80"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0 -2L20 40L0 82"
              vectorEffect="non-scaling-stroke"
              stroke="currentcolor"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </>
    ) : null}
  </li>
);

export default SchemaItem;
