import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const useAllImages = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childrenImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
      }
    }
  `);
  return images;
};

export const useImageForData = (imagePath) => {
  const images = useAllImages();

  if (!imagePath) {
    return null;
  }

  const image = images.edges.find(
    ({ node }) => node.relativePath === imagePath
  );

  if (!image) {
    return null;
  }

  return getImage(image.node.childrenImageSharp[0]);
};
