import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useStaticQuery, graphql } from "gatsby";

const ProductQualitySection = ({
  title,
  description,
  articleTitle,
  firstBtnTitle,
  firstBtnUrl,
  secondBtnTitle,
  secondBtnUrl,
  children,
}) => {
  const [markdown, setMarkdown] = useState({});

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
          }
          excerpt(format: MARKDOWN, pruneLength: 2147483647)
        }
      }
    }
  `);

  useEffect(() => {
    setMarkdown(
      data.allMarkdownRemark.nodes.find((e) =>
        e.frontmatter.title.includes(articleTitle)
      )
    );
  }, [data, articleTitle]);

  return (
    <div className="py-12 bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <h2 className="text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
        </div>
        <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p className="text-lg text-center lg:text-left text-gray-500">
            {description}
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10 prose prose-pink text-gray-500 mx-auto lg:max-w-none article">
            <ReactMarkdown>{markdown.excerpt}</ReactMarkdown>
            <div className="mt-8 flex text-base mx-auto justify-center lg:max-w-none">
              {firstBtnTitle && (
                <div className="rounded-md shadow">
                  <a
                    href={firstBtnUrl}
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-700 hover:bg-pink-800"
                  >
                    {firstBtnTitle}
                  </a>
                </div>
              )}
              {secondBtnTitle && (
                <div className="rounded-md shadow ml-4">
                  <a
                    href={secondBtnUrl}
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-white hover:bg-gray-50"
                  >
                    {secondBtnTitle}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
              />
            </svg>
            {/* Blockquote */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductQualitySection;
