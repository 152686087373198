import React from "react";

const TrustSection = ({ children, title }) => {
  return (
    <div className="bg-stone-100">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-stone-500 tracking-wide">
          {title}
        </p>
        <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default TrustSection;
