import React from "react";

const TestimonialsSection = ({ title, description, children }) => (
  <div className="bg-[#500724]">
    <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="space-y-12 max-w-7xl mx-auto">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
            {title}
          </h2>
          {description && (
            <p className="text-xl text-gray-300">{description}</p>
          )}
        </div>
        <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
          {/* Testimonials list */}
          {children}
        </ul>
      </div>
    </div>
  </div>
);

export default TestimonialsSection;
